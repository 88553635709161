import React from 'react';
import logo from '../assets/animal.png';
import '../style/App.css';

function App() {
    return (
        <div className='App'>
            <img src={logo} className="App-logo" alt="logo"/>
        </div>
    );
}

export default App;
